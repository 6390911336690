body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.full-grid {
  width: 100%;
}

.RaLogin-main-1 {
  background-position: bottom;
}

.RaLogin-avatar-3 {
  height: 70px;
  background-image: url('/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.RaLogin-avatar-3 div {
  display: none;
}

.Layout {
  padding-top: 50px;
}

/* header.MuiAppBar-positionFixed {
  top: 80px;
}*/
.Layout > div,
.layout > div {
  margin-top: 70px !important;
}
/* .Layout-appFrame-2 {
  margin-top: 70px !important;
} */

.school-name {
  font-size: 3.2vw;
}
#react-admin-title {
  font-size: 3vw;
}
.MuiToolbar-root button[aria-label='Deletar'] {
  display: none;
}

@media screen and (min-width: 800px) {
  .school-name {
    font-size: 25px;
  }
  #react-admin-title {
    font-size: 20px;
  }
}
